<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Write the net ionic equation for the chemical reaction described below. Note that any
        product not in the solid phase can be assumed to be aqueous (aq).
      </p>

      <p class="pl-8 mb-5">
        <stemble-latex :content="rxnDescription" />
      </p>

      <chemical-notation-input
        v-model="inputs.netIonic"
        dense
        :show-note="true"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question226d',
  components: {
    ChemicalNotationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        netIonic: null,
      },
      reactions: [
        '$\\text{Aqueous lead(II) nitrate plus aqueous potassium iodide}$',
        '$\\text{Aluminum metal plus aqueous zinc chloride}$',
        '$\\text{Aqueous cesium iodide plus an aqueous solution of bromine}$',
      ],
    };
  },
  computed: {
    rxnNumber() {
      return this.taskState.getValueBySymbol('rxnNumber').content;
    },
    rxnDescription() {
      return this.reactions[this.rxnNumber.value - 1];
    },
  },
};
</script>
